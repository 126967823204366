<template>
  <div class="home">
    <div class="content-wrap">
      <div v-if="loading == false" class="invoice">

        <router-link to="/" class="logo"><img src="../../assets/images/logo.png" width="250" height="93"></router-link>

        <p style="margin:70px 0 20px 0">
          <small style="display:block;font-weight:400;margin-bottom:5px;font-size:12px">InTime Autovermietung • Jacob-Schüle-Straße 29 • 73655 Plüderhausen</small>
          <span v-if="invoice.company">{{ invoice.company }}<br></span>
          {{ invoice.first_name }} {{ invoice.last_name }}<br>
          {{ invoice.street_name }} {{ invoice.street_number }}<br>
          {{ invoice.zip_code }} {{ invoice.city }}
        </p>

        <div v-if="invoice.reservation">
          <p v-if="invoice.reservation.invoice_details" style="white-space: pre-wrap;">{{ invoice.reservation.invoice_details }}</p>
        </div>

        <h3 v-if="invoice_canceled" style="margin-bottom:10px;margin-top:50px">Stornorechnung #00{{ invoice.number }}</h3>
        <h3 v-else style="margin-bottom:10px;margin-top:50px">Rechnung #00{{ invoice.number }}</h3>

        <p style="margin-top:0;margin-bottom:30px">
          Rechnungsdatum: {{ invoice.date_formatted }}
          <span v-if="invoice.reservation">– Abholort: {{ invoice.reservation.pickup_location.name }}</span>
          <span v-if="invoice.reservation">
            <span v-if="invoice.reservation.payment_option == 'sepa'"> – Bezahlmethode: SEPA Lastschrift</span>
            <span v-if="invoice.reservation.payment_option == 'card'"> – Bezahlmethode: Kreditkarte</span>
            <span v-if="invoice.reservation.payment_option == 'sofort'"> – Bezahlmethode: SOFORT</span>
            <span v-if="invoice.reservation.payment_option == 'apple_pay'"> – Bezahlmethode: Apple Pay</span>
            <span v-if="invoice.reservation.payment_option == 'cash'"> – Bezahlmethode: Bar</span>
            <span v-if="invoice.reservation.payment_option == 'ec_card'"> – Bezahlmethode: EC-Karte</span>
            <span v-if="invoice.reservation.payment_option == 'bank_transfer'"> – Bezahlmethode: Überweisung</span>
          </span>
        </p>

        <div v-if="invoice.reservation">
          <p v-if="invoice.reservation.order_number">
            Ordernummer: {{ invoice.reservation.order_number }}
            <span v-if="invoice.reservation.aks"><br>AKZ: {{ invoice.reservation.aks }}</span>
          </p>

          <p>
            Mietzeitraum: {{ invoice.reservation.pickup_timestamp }} bis {{ invoice.reservation.return_timestamp }}<br>
            Fahrzeugtyp: {{ category.name }} – Kennzeichen: {{ vehicle.license_plate }}
          </p>
        </div>

        <div v-if="invoice.damage">
          <p v-if="invoice.damage.damage_number">Schadennummer: {{ invoice.damage.damage_number }}</p>
          <p v-if="invoice.damage.dhl_number">DHL Schadennummer: {{ invoice.damage.dhl_number }}</p>
        </div>

        <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
          <thead>
            <tr>
              <th width="50%">Beschreibung</th>
              <th width="50%" style="text-align:right">Kosten</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in invoice.invoice_items" v-bind:key="item.id">
              <td style="white-space: pre-wrap">{{ item.description }}</td>
              <td style="text-align:right">{{ parseFloat(item.sum_gross).toFixed(2) }} €</td>
            </tr>

            <tr style="background:#F6F9FD;color:#495057;border-top:3px solid rgb(222, 226, 230)">
              <td><strong>Gesamtbetrag (Netto)</strong></td>
              <td style="text-align:right">{{ parseFloat(invoice.sum_net).toFixed(2) }} €</td>
            </tr>
            <tr v-if="invoice.reservation" style="background:#F6F9FD;color:#495057">
              <td><strong>+ {{ invoice.reservation.tax_rate }}% MwSt</strong></td>
              <td style="text-align:right">{{ parseFloat(invoice.sum_tax).toFixed(2) }} €</td>
            </tr>
            <tr style="background:#444;color:#fff">
              <td><strong>GESAMTBETRAG</strong></td>
              <td style="text-align:right"><strong>{{ parseFloat(invoice.sum_gross).toFixed(2) }} €</strong></td>
            </tr>
          </tbody>
        </table>

        <div v-if="invoice.damage">
          <p style="text-align:right;margin:-40px 0 20px 0;font-size:14px">(Nicht steuerbar, Schadensersatz)</p>
        </div>

        <div class="row row-gutter-20">
          <div class="col-8">
            <p>
              InTime Autovermietung<br>
              Inh. Erkan Korkmaz<br>
              Jakob-Schüle-Str. 29<br>
              73655 Plüderhausen
            </p>
          </div>
          <div class="col-8">
            <p style="text-align:center">
              Tel.: 07181/9941333<br>
              Mobil: 0157/83666161<br>
              info@intime-autovermietung.de<br>
              www.intime-autovermietung.de
            </p>
          </div>
          <div class="col-8">
            <p style="text-align:right">
              Commerzbank Schorndorf<br>
              IBAN: DE 64 6004 0071 0285 0261 00<br>
              BIC: COBADEFFXXX<br>
              Kontoinhaber: Erkan Korkmaz
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'invoice',
  data () {
    return {
      loading: true,
      invoice: {},
      category: {},
      vehicle: {},
      invoice_canceled: false
    }
  },
  methods: {
    get_data() {

      if (this.$route.query.canceled && this.$route.query.canceled == "true") {
        this.invoice_canceled = true;
      }

      axios.get(process.env.VUE_APP_BASE_API+'/v1/invoices/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.invoice = response.data.invoice;

        if (this.invoice.reservation) {
          axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.invoice.reservation.category_id, { headers: { Authorization: this.$store.getters.getToken }})
          .then(response => {
            console.log(response);
            this.category = response.data.vehicle_category;
          })
          .catch(error => {
            console.log(error);
          })

          axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.invoice.reservation.vehicle_id, { headers: { Authorization: this.$store.getters.getToken }})
          .then(response => {
            console.log(response);
            this.vehicle = response.data.vehicle;
          })
          .catch(error => {
            console.log(error);
          })
        }

        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

  .invoice {
    margin: auto;
    max-width: 1200px;

    h3 {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

</style>
